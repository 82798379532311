<template>
   <div class="-games-index-page">
          <!-- <div class="js-replace-heading-sub-title-seo-container text-center">
              <h2 class="-sub-title ">แนะนำ เกมคาสิโนสด คาสิโนออนไลน์เว็บตรง</h2>
          </div> -->
          <div class="x-category-provider js-game-scroll-container js-game-container">
              <div class="x-category-provider js-game-scroll-container js-game-container">
                  <div class="-games-list-container container">
                      <div class="x-hot-games-container pt-2">
                          <div class="-inner-heading-wrapper">
                              <img src="@/assets/main/ic-nav-menu-popular-casino.png" class="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width="55" height="55">
                              <span class="-title">คาสิโนออนไลน์ ยอดนิยม</span>
                          </div>
                          <nav class="nav-menu" id="navbarProvider">
                            <ul class="nav nav-pills">
                              <li class="nav-item -game-casino-macro-container" :class="{ 'd-none d-sm-none d-md-block': (index == 3) }" v-for="(item,index) in hot_casinno"  v-bind:key="index">
                                <div class="x-game-list-item-macro js-game-list-toggle -big " data-status="">
                                  <div class="-inner-wrapper">
                                    <picture>
                                      <a href="#" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" v-if="isMember === true">
                                        <img :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                      </a>
                                      <a href="#loginModal" v-b-modal.modal-user-login class="-btn -btn-play js-account-approve-aware"  v-else>
                                        <img :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                      </a>
                                      <div class="hotGame">
                                        <img class="img-responsive-logo" :src="require('@/assets/images/ico-hot.png')">
                                      </div>
                                    </picture>
                                    <div class="-overlay">
                                      <div class="-overlay-inner">
                                        <div class="-wrapper-container">
                                          <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" v-if="isMember === true">
                                            <i class="fas fa-play"></i>
                                            <span class="-text-btn">เข้าเล่น</span>
                                          </a>
                                          <a href="#loginModal" v-b-modal.modal-user-login class="-btn -btn-play js-account-approve-aware" v-else>
                                            <i class="fas fa-play"></i>
                                            <span class="-text-btn">เข้าเล่น</span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <div class="-title card-section ">{{ item.games_provider_id }}</div>
                              </div>
                            </li>
                          </ul>
                          </nav>
                          <div class="x-info-content-bottom-container -no-line-top -no-line-bottom lazyloaded bg-radius p-0 bg-white mt-1">
                            <div class="container">
                                <div class="-sub-description-inner-wrapper p-3">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 -content-container ">
                                            <div class="-content-wrapper text-center">
                                              <img class=" wp-image-135 aligncenter" :src="require('@/assets/images/5starlongblack.png')" style="width: 30%;max-width:300px" v-if="AGENT_S_BASE=='5STARLOTTO'"/>
                                              <img class=" wp-image-135 aligncenter" :src="require('@/assets/images/'+getLogo2)" style="width: 30%;max-width:300px" v-else/>
                                              <h1 style="text-align: center;"><span class="-head">ยินดีต้อนรับสู่ {{ AGENT_S_BASE }}</span></h1>
                                              <div class="dot-1"><i></i></div>
                                              <h3 class="-description ">
                                                {{ AGENT_S_BASE }} ของเราเปิดให้บริการคาสิโนออนไลน์แบบครบวงจร รวมค่ายชั้นนำทั้ง <span>คาสิโนออนไลน์</span>, <span>สล็อตออนไลน์</span>,  <span>พนันบอล</span>, <span>หวยออนไลน์</span> และอื่นๆอีกมากมาย
                                                ต้องการเข้าเล่นเกมคาสิโนประเภทไหนแค่เข้าเล่น กับเราที่นี่ ก็เลือกได้ตามชอบเลย
                                                เป็นการลงทุนที่เชื่อถือได้แบบ 100% มีสถานะทางการเงินสุดมั่นคง <span>จ่ายจริง</span> ครบถ้วน โดดเด่นด้วย<span class="-description2">ระบบฝาก-ถอนแบบออโต้</span>
                                                ที่จะช่วยให้เงิน-เข้าออกบัญชีของสมาชิกเป็นเรื่องง่ายขึ้นกว่าเดิม พร้อมทั้งเจ้าหน้าที่คอยให้บริการทุกช่วงเวลา หากมีปัญหาการเล่น สามารถสอบถามได้ทันที
                                              </h3>
                                              <router-link  v-if="isMember === false"  to="/register-verify" class="btn btn-pink btn-lg px-lg-5 mt-4">สมัครสมาชิก</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="-hot-slot-container m-0 mt-1" v-for="(item_h,key_h) in hot_games"  v-bind:key="key_h">
                              <div class="-inner-heading-wrapper">
                                  <img src="@/assets/main/ic-nav-menu-popular-casino.png" class="-icon" alt="เกมคาสิโนยอดนิยม เกมสล็อตยอดนิยม" width="55" height="55">
                                  <span class="-title">เกมสล็อต {{ key_h }} แนะนำ</span>
                              </div>
                              <nav class="nav-menu" id="navbarProvider">
                                  <ul class="nav nav-pills">
                                     <li class="nav-item -game-casino-macro-container"  :class="{ 'd-none d-sm-none d-md-block': (index == 3) }"  v-for="(item,index) in item_h" v-bind:key="index">
                                      <div class="x-game-list-item-macro js-game-list-toggle -big " data-status="">
                                        <div class="-inner-wrapper">
                                          <picture>
                                            <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, item.games_image, item.games_provider_id)" v-if="isMember">
                                              <img  :src="item.games_image"  class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                            </a>
                                            <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" v-b-modal.modal-user-login v-else>
                                              <img  :src="item.games_image"  class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                                            </a>
                                            <div class="hotGame">
                                              <img class="img-responsive-logo" :src="require('@/assets/images/ico-hot.png')">
                                            </div>
                                          </picture>
                                          <div class="-overlay">
                                            <div class="-overlay-inner">
                                              <div class="-wrapper-container">
                                                <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, item.games_image, item.games_provider_id)" v-if="isMember">
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                                </a>
                                                <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" v-b-modal.modal-user-login v-else>
                                                  <i class="fas fa-play"></i>
                                                  <span class="-text-btn">เข้าเล่น</span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      <div class="-title card-section ">{{ item.games_name }}</div>
                                    </div>
                                  </li>
                                  </ul>
                              </nav>
                          </div>
                          <div class="x-info-content-bottom-container -no-line-top -no-line-bottom lazyloaded bg-radius p-0 bg-white mt-1">
                            <div class="container">
                                <div class="-sub-description-inner-wrapper p-3">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 -content-container ">
                                            <div class="-content-wrapper m-0">
                                                <h1 style="text-align: center;"><span class="-head">เกมสล็อต {{ AGENT_S_BASE }}</span></h1>
                                                <h2 class="text-sup text-center">เลือกค่ายเกมที่ต้องการทดลองเล่น {{ AGENT_S_BASE }} เกมสล็อตและเกมคาสิโนออนไลน์</h2>
                                                <div class="dot-1"><i></i></div>
                                                <h3 class="-description">
                                                  <span>{{ AGENT_S_BASE }} สล็อตเว็บตรง</span> มีค่ายเกมสล็อตและเกมคาสิโนชั้นนำให้เลือกเล่นมากกว่าใคร
                                                  ตอบโจทย์ของเหล่านักเดิมพันมากที่สุด สามารถเลือกเล่นตามความต้องการได้แบบไร้ขีดจำกัด ไม่ว่าจะเป็น
                                                  <i>เกมคาสิโนสด, แทงบอลออนไลน์, บาคาร่า , และสล็อตออนไลน์</i>เล่นง่าย ได้เงินจริง สมัครสมาชิกฟรี
                                                  <span >ยูสเดียวไม่ต้องโยกเงิน บริการผ่านระบบออโต้ที่ทันสมัย</span>
                                                  ภาพสวยคมชัดระดับ Full HD พร้อมทีมงานผู้เชี่ยวชาญที่คอยให้บริการตลอด 24 ชั่วโมง  <span >บริการ ฝาก - ถอน
                                                  รวดเร็วทันใจ</span> เปิดให้บริการทุกวัน 24 ชม. สมัครสมาชิกฟรีได้ที่ลิ้งนี้
                                                  </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container p-2">
                              <ul class="nav provider-list justify-content-center">
                                <li class="d-flex justify-content-center align-items-center" v-for="(item,index) in ex_game"  v-bind:key="index">
                                  <a  href="javascript:;">
                                    <img class="img-fluid ls-is-cached lazyloaded" :src="require('@/assets/hotgame/'+item.img)">
                                  </a>
                                </li>
                              </ul>
                              <div class="col-lg-12  text-center py-2">
                                <router-link to="/slot" class="btn btn-pink btn-lg px-lg-5 mt-4">เล่นเกมสล็อต</router-link>
                              </div>
                            </div>
                            <div class="position-relative overflow-hidden p-3 pb-md-5  bg-blue bg-blue-inner">
                              <div class="container text-center">
                                <div class="col-xxl-8 me-auto my-5 ">
                                  <h1 class="text-white"><span class="text-blue">{{ AGENT_S_BASE }}</span> พนันสด เกมคาสิโนถ่ายทอดสด</h1>
                                  <h2 class="text-muted mb-2">สนุกไปกับพนันสดกับสาวๆสุดเซ็กซี่ บรรยากาศเสมือนจริงสุดๆ</h2>
                                  <div class="dot-2"><i></i></div>
                                  <h3 class="text-white mt-3 mt-lg-4 -description ">
                                    <span class="text-warning text-uppercase">Live Casino</span>
                                    หรือ <i>เกมพนันสด เกมถ่ายทอดสด</i> {{ AGENT_S_BASE }} เรามาพร้อมกับสาวสวยเซ็กซี่ เป็นผู้ดำเนินการประจำโต๊ะ
                                    บรรยากาศเหมือนเข้าไปเล่นในบ่อนจริงๆ ภาพสวยคมชัดระดับ Full HD พร้อมทีมงานผู้เชี่ยวชาญที่คอยให้บริการตลอด<strong> 24 ชั่วโมง </strong>
                                    มีค่ายเกมชั้นนำให้เลือกเล่น อย่าง <i>Sexy baccarat, SA gaming, Evolution Gaming, Pragmaticplay, Dream gaming, VENUS, WM เป็นต้น </i>
                                    เกมของ {{ AGENT_S_BASE }} ทุกค่ายรองรับมือถือ ทั้งระบบ Androin และ IOS สามารถเลือกเล่นได้ทั้ง บาคาร่า, ไฮโล, รูเร็ต และเกมอื่นๆที่อยู่ในหมวดหมู่ของ
                                    <strong> พนันสด เกมถ่ายทอดสด </strong> จาก {{ AGENT_S_BASE }}</h3>
                                </div>
                                <img alt="พนันสด"  :src="require('@/assets/hotgame/list-livedealer.webp')" class="img-fluid ls-is-cached lazyloaded">
                                <router-link to="/casino" class="btn btn-pink btn-lg px-lg-5 mt-4">เล่นพนันสด</router-link>
                              </div>
                              <div class="bg-people"></div>
                            </div>
                            <div class="position-relative overflow-hidden pt-md-5  bg-white bg-white-inner">
                              <div class="container">
                                <div class="row m-0">
                                  <div class="col-lg-4">
                                    <img alt="เกมคาสิโนสด" :src="require('@/assets/hotgame/new-sport.png')" class="img-fluid lazyloaded" >
                                  </div>
                                  <div class="col-lg-8  text-center py-2">
                                    <h1>เดิมพันกีฬา เว็บพนันบอล</h1>
                                    <h2 class="text-sub mb-2">{{ AGENT_S_BASE }} มีบริการแทงบอลออนไลน์ โดยที่ท่านไม่ต้องโยกเงิน <strong> ยูสเดียวแทงได้ทุกลีก</strong></h2>
                                  <div class="dot-1"><i></i></div>
                                  <h3 class="mt-3 mt-lg-4 -description">{{ AGENT_S_BASE }} เปิดเว็บพนันบอล <i>เดิมพันกีฬาโดยตรงได้จาก OBET, AFBSPORT</i>
                                    ครบทุกลีกไม่ว่าจะลีกใหญ่ ลีกเล็ก ซึ่งท่านสามารถเดิมพันบอลสเต็ป2-12คู่ <strong> เรตค่าน้ำดีที่สุดในไทย </strong>
                                    เว็บพนันบอล 2023 เป็นกีฬาระดับโลก ที่ผู้คนต่างให้ความสนใจกันค่อนข้างมาก เดิมพันบอลไปกับทีมที่คุณชอบ
                                    อกจากท่านจะสามารถแทงบอลผ่าน {{ AGENT_S_BASE }} ได้แล้ว ยังมีเกมกีฬาอื่นๆอีกเพียบ<i> เช่น บาสเก็ตบอล, ไก่ชน,
                                      กีฬาเสมือนจริง, คาสิโนเกมส์สด, คีโน, การแข่ง, เทนนิส,เบสบอล, มวยสากล, ฟุตซอล, ฮอกกี้, เกมส์ปาเป้า,
                                      จักรยาน, วอลเล่ย์บอล, รักบี้, เทเบิลเทนนิส, เป็นต้น </i> ที่ครอบคลุมแมตท์ทั้งหมดมากกว่า 3,000 รายการ
                                      เรียกได้ว่าสมัครที่นี้มีครบสามารถเลือกเล่นเพียงท่านเป้นสมาชิกกับเรา</h3>
                                      <router-link to="/sport" class="btn btn-pink btn-lg px-lg-5 mt-4">แทงบอลออนไลน์</router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="position-relative overflow-hidden p-3 pb-md-5  bg-blue bg-blue-inner">
                              <div class="container text-center">
                                <div class="col-xxl-12 me-auto">
                                  <h1 class="text-white"><span class="text-blue">{{ AGENT_S_BASE }}</span> หวยออนไลน์</h1>
                                  <h2 class="text-muted mb-2">สนุกไปกับการเดิมพันหวยทุกประเภท <strong>ตลอดเวลา 24 ชั่วโมง </strong></h2>
                                  <div class="dot-2"><i></i></div>
                                  <h3 class="text-white mt-3 mt-lg-4 -description">
                                    <span class="text-warning text-uppercase ">Lottery online</span>
                                    หรือ <i>หวยออนไลน์</i> {{ AGENT_S_BASE }} เรามาพร้อมกับหวยทุกประเภทให้ร่วมสนุก
                                    <i>ทั้งหวยรัฐบาล หวยยี่กี หวยลาว ฮานอย หวยหุ้นทั้งในและต่างประเทศ เป็นต้น</i>
                                    การบริการรวดเร็วทันใจ จ่ายบาทละ 900 อัพเดทตลอดเวลา<strong> 24 ชั่วโมง </strong>
                                    แทงหวยกับเราที่นี่พร้อมรับผลตอบแทนที่ดีกับทุกประเภทการเดิมพันพร้อมลุ้นรางวัลใหญ่ได้ทุกวันมีโอกาสต่อยอดกำไรได้หลายเท่าตัวจากการเดิมพัน
                                    ทุนน้อยก็สนุกได้พร้อมเริ่มต้นแทงหวยออนไลน์ด้วยทุนขั้นต่ำเพียงแค่ 1 บาทเท่านั้นครบครันทุกการเดิมพันและมีโอกาสในการกอบโกยเงินรางวัลได้สูงเป็นอย่างมาก
                                    แทงหวยกับเราสะดวกสบายใช้บริการได้ผ่านทางโทรศัพท์มือถือเพียงแค่ปลายนิ้วสัมผัสเท่านั้น
                                  </h3>
                                  <div class="row m-0">
                                  <div class="col-6 col-sm-6 col-md-3">
                                    <img alt="พนันสด"  :src="require('@/assets/hotgame/lot-01.webp')" class="img-fluid ls-is-cached lazyloaded">
                                  </div>
                                  <div class="col-6 col-sm-6 col-md-3">
                                    <img alt="พนันสด"  :src="require('@/assets/hotgame/lot-03.webp')" class="img-fluid ls-is-cached lazyloaded">
                                  </div>
                                  <div class="col-6 col-sm-6 col-md-3">
                                    <img alt="พนันสด"  :src="require('@/assets/hotgame/lot-02.webp')" class="img-fluid ls-is-cached lazyloaded">
                                  </div>
                                  <div class="col-6 col-sm-6 col-md-3">
                                    <img alt="พนันสด"  :src="require('@/assets/hotgame/lot-04.webp')" class="img-fluid ls-is-cached lazyloaded">
                                  </div>
                                </div>
                                </div>
                                <router-link to="/lotto" class="btn btn-pink btn-lg px-lg-5 mt-4">เดิมพันหวย</router-link>
                              </div>
                              <div class="bg-people"></div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</template>

<script>
import { ApiService } from '@/utils'
import { mapState, mapActions, mapGetters } from 'vuex'
const client = new ApiService({})

export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      // await this.getBalanceInfo()
    } catch (error) {
    }
  },
  data () {
    return {
      AGENT_EXT_NAME: process.env.VUE_APP_EXT_NAME,
      hot_casinno: [
        { id: 60, games_provider_id: 'PRAGMATICPLAY' },
        { id: 648, games_provider_id: 'SA' },
        { id: 751, games_provider_id: 'SEXYBCRT' },
        { id: 567, games_provider_id: 'DREAMGAMING' }
      ],
      ex_game: [
        { name: 'redtiger', img: 'redtiger.png' },
        { name: 'pragmatic', img: 'pragmatic.png' },
        { name: 'pg', img: 'pg.png' },
        { name: 'joker', img: 'joker.png' }
      ],
      hot_games: {
        PGSLOT: [
          { id: 2547, games_name: 'Hawaiian Tiki', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-114.png', games_provider_id: 'PGSLOT' },
          { id: 2547, games_name: 'Super Golf Drive', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-118.png', games_provider_id: 'PGSLOT' },
          { id: 2542, games_name: 'Songkran Splash', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-116.png', games_provider_id: 'PGSLOT' },
          { id: 2541, games_name: 'Bakery Bonanza', games_image: 'https://images-store.foxg-gaming.biz/PGSLOT/PG-SLOT-115.png', games_provider_id: 'PGSLOT' }
        ],
        JOKER: [
          { id: 2548, games_name: 'Leprechaun', games_image: 'https://images-store.foxg-gaming.biz/JOKER/zezjtt6ras7ms.png', games_provider_id: 'JOKER' },
          { id: 2545, games_name: 'Kraken Hunter', games_image: 'https://images-store.foxg-gaming.biz/JOKER/ooekf9x16xaxn.png', games_provider_id: 'JOKER' },
          { id: 1516, games_name: 'Wukong', games_image: 'https://images-store.foxg-gaming.biz/JOKER/texkt79w6ziqs.png', games_provider_id: 'JOKER' },
          { id: 1524, games_name: 'Three Kingdoms 2', games_image: 'https://images-store.foxg-gaming.biz/JOKER/yqe1n9d7qj3zy.png', games_provider_id: 'JOKER' }
        ],
        PRAGMATICPLAY: [
          { id: 1782, games_name: 'Wild West Gold Megaways', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vswayswildwest.png', games_provider_id: 'PRAGMATICPLAY' },
          { id: 1112, games_name: 'Cowboys Gold', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs10cowgold_tour.png', games_provider_id: 'PRAGMATICPLAY' },
          { id: 1265, games_name: 'Wild West Gold', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs40wildwest_tour.png', games_provider_id: 'PRAGMATICPLAY' },
          { id: 1441, games_name: 'The Ultimate 5', games_image: 'https://images-store.foxg-gaming.biz/PRAGMATICPLAY/vs20ultim5.png', games_provider_id: 'PRAGMATICPLAY' }
        ]
      },
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE
    }
  },
  computed: {
    ...mapGetters([
      'getGames',
      'getGameUrl',
      'getLogo2'
    ]),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    })
  },
  methods: {
    ...mapActions(['getGamesList', 'getGamesUrl', 'setGameDepositCreditAll', 'getBalanceInfo', 'setLoading']),
    async reqStartGame (gameId, game_img, game_name) {
      this.setLoading(true)
      const resp = await client.post('/game/request/start', JSON.stringify({
        id: gameId
      }))
      this.setLoading(false)
      if (resp.status_code === 'SUCCESS') {
        this.link_to_game = process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token
        this.$swal({
          text: 'คุณต้องการเข้าเล่น ' + game_name + ' ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ปิด',
          imageUrl: game_img,
          imageWidth: '100%'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token)
          }
        })
      }
    }
  }
}
</script>

<style>
 .-sub-title {
    margin-top: 2rem;
       margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
</style>
