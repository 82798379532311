<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div
        class="p-0 w-100 main-content align-self-stretch"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            rounded
            p-0
            xtarget
            col-lotto
          "
        >
          <!-- <h3 class="title-huay p-2 m-0">ลอตเตอรี่</h3> -->
          <div class="row m-0 pt-1">
            <div class="col-12 col-md-12 p-0 br_radius br_shadow br_color2 f9" v-for="(item, index) in list" :key="index"  >
              <router-link
                :to="`/member/lottery/${item.LottoCatagory.lottoId}`"
                style="padding: 0"
                class="btn btn-outline-primary btn-block">
                <img src="@/assets/images/A63_lottory.webp" class="w-100">
                <!-- <h2 class="m-0 title-lottery"> <img src="@/assets/menu_center/lotto.png"></h2> -->
              </router-link>
              <!-- <router-link
                :to="`/member/lottery/${item.LottoCatagory.lottoId}`"
                style="padding: 0"
                :style="{ 'background-image': 'url(' + require('@/assets/images/bg-group-lotto.png') + ')' }"
                class="btn btn-outline-primary btn-block btn-contactbox  shadow-sm pt-2 bg_yellow ">
                <h1> <img src="@/assets/menu_center/lotto.png" width="50" height="50">ลอตเตอรี่</h1>
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAvailableLottoTicket } from '@/services'
import { mapState, mapActions } from 'vuex'
import { getClassIcons } from '@/utils'
export default {
  async created () {
    try {
      this.setLoading(true)
      const resp = await getAvailableLottoTicket()
      if (resp.status_code === 'SUCCESS') {
        this.list = resp.data.listAvaliableLotto[0].list
      }
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    }),
    getIconClass (title) {
      if (title) {
        return getClassIcons(title)
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      list: []
    }
  }
}
</script>
<style type="text/css">
.bg_yellow {
  border-radius: 10px;
  background-color: rgb(250, 231, 126, 0.25);
}
.title-lottery{
  position: absolute;
  float: left;
  top: 13%;
  left: 13%;
}
.title-lottery img{
  width: 70%;
}
.display-rudy{
  display: ruby;
}
</style>
